import React from 'react';
import { Box, useDisclosure } from '@chakra-ui/core';

import { AudioModal, TermsAndConditionsModal } from '@/components/ui';
import { HomeDesktop, HomeMobile } from './components';

type Props = {
  termsAndConditionsModal: ReturnType<typeof useDisclosure>;
  audioModal: ReturnType<typeof useDisclosure>;
};

const Home: React.FC<Props> = ({ termsAndConditionsModal, audioModal }) => {
  return (
    <Box as="main" height="full" width="full">
      <Box display={['none', 'flex']} height="full" width="full">
        <HomeDesktop
          data-testid="home-desktop"
          onOpenTAndCModal={termsAndConditionsModal.onOpen}
          onOpenAudioModal={audioModal.onOpen}
        />
      </Box>
      <Box display={['flex', 'none']} height="full" width="full">
        <HomeMobile
          data-testid="home-mobile"
          onOpenTAndCModal={termsAndConditionsModal.onOpen}
          onOpenAudioModal={audioModal.onOpen}
        />
      </Box>
      <TermsAndConditionsModal
        isOpen={termsAndConditionsModal.isOpen}
        onClose={termsAndConditionsModal.onClose}
      />
      <AudioModal isOpen={audioModal.isOpen} onClose={audioModal.onClose} />
    </Box>
  );
};

export default Home;
