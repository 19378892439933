/* eslint-disable @typescript-eslint/no-var-requires */
// AUTO-GENERATED - Do not manually edit, use `yarn import:content`

const day01scene01 = require('./day01scene01.svg') as string;
const day01scene02 = require('./day01scene02.svg') as string;
const day01scene03 = require('./day01scene03.svg') as string;
const day01scene04 = require('./day01scene04.svg') as string;
const day01scene05 = require('./day01scene05.svg') as string;
const day01scene06 = require('./day01scene06.svg') as string;
const day01scene07 = require('./day01scene07.svg') as string;
const day01scene08 = require('./day01scene08.svg') as string;
const day01scene09 = require('./day01scene09.svg') as string;
const day01scene10 = require('./day01scene10.svg') as string;
const day01scene11 = require('./day01scene11.svg') as string;
const day01scene12 = require('./day01scene12.svg') as string;
const day01scene13 = require('./day01scene13.svg') as string;
const day01scene14 = require('./day01scene14.svg') as string;
const day01scene15 = require('./day01scene15.svg') as string;
const day01scene16 = require('./day01scene16.svg') as string;
const day01scene17 = require('./day01scene17.svg') as string;
const day01scene18 = require('./day01scene18.svg') as string;
const day01scene19 = require('./day01scene19.svg') as string;
const day01scene20 = require('./day01scene20.svg') as string;
const day01scene21 = require('./day01scene21.svg') as string;
const day01scene22 = require('./day01scene22.svg') as string;
const day01scene23 = require('./day01scene23.svg') as string;
const day01scene24 = require('./day01scene24.svg') as string;
const day01scene25 = require('./day01scene25.svg') as string;
const day01scene26 = require('./day01scene26.svg') as string;
const day01scene27 = require('./day01scene27.svg') as string;
const day01scene28 = require('./day01scene28.svg') as string;
const day01scene29 = require('./day01scene29.svg') as string;
const day01scene30 = require('./day01scene30.svg') as string;
const day01scene31 = require('./day01scene31.svg') as string;
const day01scene32 = require('./day01scene32.svg') as string;
const day01scene33 = require('./day01scene33.svg') as string;
const day01scene34 = require('./day01scene34.svg') as string;
const day01scene35 = require('./day01scene35.svg') as string;
const day01scene36 = require('./day01scene36.svg') as string;
const day01scene37 = require('./day01scene37.svg') as string;
const day01scene38 = require('./day01scene38.svg') as string;
const day01scene39 = require('./day01scene39.svg') as string;
const day01scene40 = require('./day01scene40.svg') as string;
const day01scene41 = require('./day01scene41.svg') as string;
const day01scene42 = require('./day01scene42.svg') as string;
const day01scene43 = require('./day01scene43.svg') as string;
const day01scene44 = require('./day01scene44.svg') as string;
const day01scene45 = require('./day01scene45.svg') as string;
const day01scene46 = require('./day01scene46.svg') as string;
const day01scene47 = require('./day01scene47.svg') as string;
const day01scene48 = require('./day01scene48.svg') as string;
const day01scene49 = require('./day01scene49.svg') as string;
const day01scene50 = require('./day01scene50.svg') as string;
const day01scene51 = require('./day01scene51.svg') as string;
const day01scene52 = require('./day01scene52.svg') as string;
const day01scene53 = require('./day01scene53.svg') as string;
const day02scene54 = require('./day02scene54.svg') as string;
const day02scene55 = require('./day02scene55.svg') as string;
const day02scene56 = require('./day02scene56.svg') as string;
const day02scene57 = require('./day02scene57.svg') as string;
const day02scene58 = require('./day02scene58.svg') as string;
const day02scene59 = require('./day02scene59.svg') as string;
const day02scene60 = require('./day02scene60.svg') as string;
const day02scene61 = require('./day02scene61.svg') as string;
const day02scene62 = require('./day02scene62.svg') as string;
const day02scene63 = require('./day02scene63.svg') as string;
const day02scene64 = require('./day02scene64.svg') as string;
const day02scene65 = require('./day02scene65.svg') as string;
const day02scene66 = require('./day02scene66.svg') as string;
const day02scene67 = require('./day02scene67.svg') as string;
const day02scene68 = require('./day02scene68.svg') as string;
const day02scene69 = require('./day02scene69.svg') as string;
const day02scene70 = require('./day02scene70.svg') as string;
const day02scene71 = require('./day02scene71.svg') as string;
const day02scene72 = require('./day02scene72.svg') as string;
const day02scene73 = require('./day02scene73.svg') as string;
const day02scene74 = require('./day02scene74.svg') as string;
const day02scene75 = require('./day02scene75.svg') as string;
const day02scene76 = require('./day02scene76.svg') as string;
const day02scene77 = require('./day02scene77.svg') as string;
const day02scene78 = require('./day02scene78.svg') as string;
const day02scene79 = require('./day02scene79.svg') as string;
const day02scene80 = require('./day02scene80.svg') as string;
const day02scene81 = require('./day02scene81.svg') as string;
const day02scene82 = require('./day02scene82.svg') as string;
const day02scene83 = require('./day02scene83.svg') as string;
const day02scene84 = require('./day02scene84.svg') as string;
const day02scene85 = require('./day02scene85.svg') as string;
const day02scene86 = require('./day02scene86.svg') as string;
const day02scene87 = require('./day02scene87.svg') as string;
const day02scene88 = require('./day02scene88.svg') as string;
const day02scene89 = require('./day02scene89.svg') as string;
const day02scene90 = require('./day02scene90.svg') as string;
const day02scene91 = require('./day02scene91.svg') as string;
const day02scene92 = require('./day02scene92.svg') as string;
const day02scene93 = require('./day02scene93.svg') as string;
const day02scene94 = require('./day02scene94.svg') as string;
const day02scene95 = require('./day02scene95.svg') as string;
const day02scene96 = require('./day02scene96.svg') as string;
const day02scene97 = require('./day02scene97.svg') as string;
const day02scene98 = require('./day02scene98.svg') as string;
const day02scene99 = require('./day02scene99.svg') as string;
const day02scene100 = require('./day02scene100.svg') as string;
const day02scene101 = require('./day02scene101.svg') as string;
const day02scene102 = require('./day02scene102.svg') as string;
const day02scene103 = require('./day02scene103.svg') as string;
const day02scene104 = require('./day02scene104.svg') as string;
const day02scene105 = require('./day02scene105.svg') as string;
const day02scene106 = require('./day02scene106.svg') as string;
const day02scene107 = require('./day02scene107.svg') as string;
const day02scene108 = require('./day02scene108.svg') as string;
const day02scene109 = require('./day02scene109.svg') as string;
const day02scene110 = require('./day02scene110.svg') as string;

export default {
  day01scene01: day01scene01,
  day01scene02: day01scene02,
  day01scene03: day01scene03,
  day01scene04: day01scene04,
  day01scene05: day01scene05,
  day01scene06: day01scene06,
  day01scene07: day01scene07,
  day01scene08: day01scene08,
  day01scene09: day01scene09,
  day01scene10: day01scene10,
  day01scene11: day01scene11,
  day01scene12: day01scene12,
  day01scene13: day01scene13,
  day01scene14: day01scene14,
  day01scene15: day01scene15,
  day01scene16: day01scene16,
  day01scene17: day01scene17,
  day01scene18: day01scene18,
  day01scene19: day01scene19,
  day01scene20: day01scene20,
  day01scene21: day01scene21,
  day01scene22: day01scene22,
  day01scene23: day01scene23,
  day01scene24: day01scene24,
  day01scene25: day01scene25,
  day01scene26: day01scene26,
  day01scene27: day01scene27,
  day01scene28: day01scene28,
  day01scene29: day01scene29,
  day01scene30: day01scene30,
  day01scene31: day01scene31,
  day01scene32: day01scene32,
  day01scene33: day01scene33,
  day01scene34: day01scene34,
  day01scene35: day01scene35,
  day01scene36: day01scene36,
  day01scene37: day01scene37,
  day01scene38: day01scene38,
  day01scene39: day01scene39,
  day01scene40: day01scene40,
  day01scene41: day01scene41,
  day01scene42: day01scene42,
  day01scene43: day01scene43,
  day01scene44: day01scene44,
  day01scene45: day01scene45,
  day01scene46: day01scene46,
  day01scene47: day01scene47,
  day01scene48: day01scene48,
  day01scene49: day01scene49,
  day01scene50: day01scene50,
  day01scene51: day01scene51,
  day01scene52: day01scene52,
  day01scene53: day01scene53,
  day02scene54: day02scene54,
  day02scene55: day02scene55,
  day02scene56: day02scene56,
  day02scene57: day02scene57,
  day02scene58: day02scene58,
  day02scene59: day02scene59,
  day02scene60: day02scene60,
  day02scene61: day02scene61,
  day02scene62: day02scene62,
  day02scene63: day02scene63,
  day02scene64: day02scene64,
  day02scene65: day02scene65,
  day02scene66: day02scene66,
  day02scene67: day02scene67,
  day02scene68: day02scene68,
  day02scene69: day02scene69,
  day02scene70: day02scene70,
  day02scene71: day02scene71,
  day02scene72: day02scene72,
  day02scene73: day02scene73,
  day02scene74: day02scene74,
  day02scene75: day02scene75,
  day02scene76: day02scene76,
  day02scene77: day02scene77,
  day02scene78: day02scene78,
  day02scene79: day02scene79,
  day02scene80: day02scene80,
  day02scene81: day02scene81,
  day02scene82: day02scene82,
  day02scene83: day02scene83,
  day02scene84: day02scene84,
  day02scene85: day02scene85,
  day02scene86: day02scene86,
  day02scene87: day02scene87,
  day02scene88: day02scene88,
  day02scene89: day02scene89,
  day02scene90: day02scene90,
  day02scene91: day02scene91,
  day02scene92: day02scene92,
  day02scene93: day02scene93,
  day02scene94: day02scene94,
  day02scene95: day02scene95,
  day02scene96: day02scene96,
  day02scene97: day02scene97,
  day02scene98: day02scene98,
  day02scene99: day02scene99,
  day02scene100: day02scene100,
  day02scene101: day02scene101,
  day02scene102: day02scene102,
  day02scene103: day02scene103,
  day02scene104: day02scene104,
  day02scene105: day02scene105,
  day02scene106: day02scene106,
  day02scene107: day02scene107,
  day02scene108: day02scene108,
  day02scene109: day02scene109,
  day02scene110: day02scene110,
};
