/** @jsx jsx */
import React, { useRef, useState } from 'react';
import { css, jsx } from '@emotion/core';
import {
  Box,
  Flex,
  Heading,
  Icon,
  Image,
  Stack,
  Text,
  useTheme,
} from '@chakra-ui/core';
import type { FlexProps } from '@chakra-ui/core';

import { Header } from '@/components/layout';
import { BackButton, Button } from '@/components/ui';
import { rem } from '@/utils/theme';
import { assertNever } from '@/utils/types';
import useRerender from '@/utils/useRerender';

type Props = {
  defaultPage?: 1 | 2;
  onOpenTAndCModal: () => void;
  onOpenAudioModal: () => void;
} & FlexProps;

const HomeMobile: React.FC<Props> = ({
  defaultPage = 1,
  onOpenTAndCModal,
  onOpenAudioModal,
  ...rest
}) => {
  const theme = useTheme();
  const [page, setPage] = useState<1 | 2>(defaultPage);

  // Focus the page element that is switched to
  const firstPageRef = useRef<HTMLDivElement | null>(null);
  const secondPageRef = useRef<HTMLDivElement | null>(null);
  useRerender(() => {
    switch (page) {
      case 1:
        firstPageRef.current?.focus();
        break;
      case 2:
        secondPageRef.current?.focus();
        break;
      default:
        assertNever(page);
    }
  }, [page]);

  return (
    <Flex direction="column" width="full" {...rest}>
      <Flex
        backgroundColor="brand.text"
        direction="column"
        display={page === 1 ? 'flex' : 'none'}
        flexShrink={0}
        height="full"
        tabIndex={0}
        width="full"
        ref={firstPageRef}
      >
        <Header
          backgroundColor="brand.desertStormGrey"
          marginBottom={-3}
          paddingBottom={0}
        />
        <Flex
          alignItems="center"
          direction="column"
          flexGrow={1}
          flexShrink={1}
          justifyContent="space-between"
          marginX="auto"
          minWidth={rem('320px')}
          paddingBottom={4}
          position="relative"
          textAlign="center"
          width="full"
        >
          <Box
            flexGrow={1}
            minHeight={rem('150px')}
            maxHeight={rem('400px')}
            overflow="hidden"
            position="relative"
            width="full"
          >
            <Icon
              name="mobileHomepageShape"
              color="brand.desertStormGrey"
              height="100%"
              left="50%"
              minWidth={rem('400px')}
              position="absolute"
              transform="translateX(-50%)"
              width="100%"
              {...{
                preserveAspectRatio: 'none',
              }}
            />
            <Image
              alt=""
              src={require('@/images/akira.svg')}
              height="full"
              left="50%"
              position="absolute"
              transform="translateX(-50%)"
              width="auto"
              ignoreFallback
            />
          </Box>
          <Stack
            flexShrink={0}
            marginY={3}
            paddingX={2}
            spacing={1}
            width={rem('320px')}
          >
            <Heading
              as="h1"
              color="white"
              fontWeight="bold"
              css={css`
                font-size: ${rem('23px')};

                @media (min-width: ${rem('340px')}) {
                  font-size: ${theme.fontSizes['3xl']};
                }
              `}
            >
              Meet Akira
            </Heading>
            <Text
              color="white"
              css={css`
                font-size: ${rem('14px')};

                @media (min-width: ${rem('340px')}) {
                  font-size: ${theme.fontSizes.md};
                }
              `}
            >
              Akira is a care worker, just like you.
              <br />
              Help her bring greater meaning to
              <br />
              the people she cares for.
            </Text>
          </Stack>
          <Box flexShrink={0} paddingX={4} width="100%">
            <Button
              variant="yellow"
              onClick={() => {
                setPage(2);
              }}
              css={css`
                padding: ${theme.space['2']};

                @media (min-width: ${rem('340px')}) {
                  padding: ${theme.space['3']};
                }
              `}
            >
              Get started
            </Button>
          </Box>
          <Box flexShrink={0} paddingX={4} width="100%">
            <Button
              variant="textLink"
              alignSelf="center"
              paddingY={2}
              width="100%"
              fontSize="lg"
              onClick={onOpenTAndCModal}
              color="white"
              css={css`
                &:focus,
                &:hover {
                  text-decoration: underline;
                }
                &:focus {
                  outline: auto;
                }
              `}
            >
              T&amp;Cs apply
            </Button>
          </Box>
        </Flex>
      </Flex>
      <Flex
        backgroundColor="white"
        direction="column"
        display={page === 2 ? 'flex' : 'none'}
        flexShrink={0}
        height="full"
        tabIndex={0}
        width="full"
        ref={secondPageRef}
      >
        <Header ariaHidden marginBottom={-3} paddingBottom={0} tabIndex={-1} />
        <Stack
          alignItems="center"
          flexGrow={1}
          flexShrink={1}
          justifyContent="space-between"
          marginX="auto"
          minWidth={rem('320px')}
          paddingBottom={4}
          position="relative"
          spacing={4}
          textAlign="center"
          width="full"
        >
          <Box
            flexGrow={1}
            minHeight={rem('150px')}
            maxHeight={rem('400px')}
            overflow="hidden"
            position="relative"
            width="full"
          >
            <Icon
              name="homepageShape"
              color="brand.desertStormGrey"
              height="80%"
              left={0}
              position="absolute"
              top="50%"
              transform="translateY(-50%)"
              width="100%"
            />
            <Image
              alt=""
              src={require('@/images/akira.svg')}
              height="full"
              left="50%"
              position="absolute"
              transform="translateX(-50%)"
              width="auto"
              ignoreFallback
            />
          </Box>
          <Stack
            fontSize="sm"
            flexShrink={0}
            paddingX={2}
            spacing={1}
            css={css`
              font-size: ${rem('14px')};
              width: ${rem('320px')};

              @media (min-width: ${rem('400px')}) {
                font-size: ${theme.fontSizes.md};
                width: ${rem('360px')};
              }
            `}
          >
            <Text color="brand.text">
              Spiritual care is a way to do that.
              <br />
              Let&apos;s see how Akira goes today. See if you can find moments
              to help Akira bring a spiritual care approach to her work.
            </Text>
            <Text color="brand.text">
              This experience takes about 10 minutes.
            </Text>
          </Stack>
          <Flex
            justifyContent="space-between"
            flexShrink={0}
            paddingX={4}
            width="full"
          >
            <BackButton
              variant="white"
              paddingLeft={0}
              width="calc(50% - 8px)"
              onClick={() => {
                setPage(1);
              }}
            />
            <Button
              onClick={onOpenAudioModal}
              width="calc(50% - 8px)"
              css={css`
                padding: ${theme.space['2']};

                @media (min-width: ${rem('340px')}) {
                  padding: ${theme.space['3']};
                }
              `}
            >
              Next
            </Button>
          </Flex>
        </Stack>
      </Flex>
    </Flex>
  );
};

export default HomeMobile;
