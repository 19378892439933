import React, { useRef } from 'react';
import { Flex, Heading, Link, Text } from '@chakra-ui/core';
import type { IModal } from '@chakra-ui/core';

import { Button, Modal } from '@/components/ui';
import { rem } from '@/utils/theme';

type Props = Omit<IModal, 'children'>;

const TermsAndConditionsModal: React.FC<Props> = ({
  isOpen = false,
  onClose,
  ...rest
}) => {
  const buttonEl = useRef<HTMLButtonElement>(null);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      initialFocusRef={buttonEl}
      {...rest}
    >
      <Flex direction="column" padding={[3, 4]}>
        <Heading as="h1" fontFamily="body" fontSize={['xl', '2xl']}>
          Terms and Conditions
        </Heading>
        <Text fontSize={['lg', 'xl']} marginTop={4}>
          Meet Akira is licensed to members of Meaningful Ageing Australia for
          their internal use only.
        </Text>
        <Text fontSize={['lg', 'xl']} marginTop={3}>
          Contact us to discuss{' '}
          <Link
            href="mailto:admin@meaningfulage.org.au"
            color="brand.deepSeaGreen"
            fontWeight="semibold"
            textDecoration="underline"
          >
            admin@meaningfulage.org.au
          </Link>
        </Text>
        <Button
          ref={buttonEl}
          variant="yellow"
          marginTop={rem('40px')}
          onClick={onClose}
        >
          Got it
        </Button>
      </Flex>
    </Modal>
  );
};

export default TermsAndConditionsModal;
