// AUTO-GENERATED - Do not manually edit, use `yarn import:content`

import images from '@/images/scenes/story1';
import type { Scene } from '../types';

const scenes: Readonly<Scene[]> = [
  {
    src: images.day01scene01,
    alt:
      'Akira, a care worker, enters the room with a smile. Giorgio, her client, is awake and sitting in his chair. He looks worried and confused.',
    audioMp3: '../audio/mp3/story1/day01scene01.mp3',
    audioWav: '../audio/wav/story1/day01scene01.wav',
    hotspot: undefined,
    dialogue: {
      name: 'Akira',
      text: 'Morning Giorgio!',
    },
    animation: { type: 'fadeIn' },
    positiveMoment: 0,
  },
  {
    src: images.day01scene02,
    alt: "Akira starts tidying Giorgio's room.",
    audioMp3: '../audio/mp3/story1/day01scene02.mp3',
    audioWav: '../audio/wav/story1/day01scene02.wav',
    hotspot: undefined,
    dialogue: {
      name: 'Giorgio',
      text: 'Is my daughter awake?',
    },
    animation: undefined,
    positiveMoment: 0,
  },
  {
    src: images.day01scene03,
    alt:
      "Akira is rushing around to tidy Giorgio's room, opening the curtains and gathering his laundry.",
    audioMp3: '../audio/mp3/story1/day01scene03.mp3',
    audioWav: '../audio/wav/story1/day01scene03.wav',
    hotspot: undefined,
    dialogue: {
      name: 'Giorgio',
      text: "She will be hungry and I don't want her late for school.",
    },
    animation: undefined,
    positiveMoment: 0,
  },
  {
    src: images.day01scene04,
    alt:
      'Akira talks over her shoulder to Giorgio as she tidies. Giorgio looks confused.',
    audioMp3: '../audio/mp3/story1/day01scene04.mp3',
    audioWav: '../audio/wav/story1/day01scene04.wav',
    hotspot: undefined,
    dialogue: {
      name: 'Akira',
      text:
        "Giorgio. Your daughter is grown up now. She doesn't live with you anymore but she will be here this afternoon to visit.",
    },
    animation: undefined,
    positiveMoment: 0,
  },
  {
    src: images.day01scene05,
    alt: 'Akira checks her watch distractedly as Giorgio talks.',
    audioMp3: '../audio/mp3/story1/day01scene05.mp3',
    audioWav: '../audio/wav/story1/day01scene05.wav',
    hotspot: undefined,
    dialogue: {
      name: 'Giorgio',
      text: 'What are you talking about Akira?',
    },
    animation: undefined,
    positiveMoment: 0,
  },
  {
    src: images.day01scene06,
    alt: "Akira turns to take the laundry from Giorgio's bed.",
    audioMp3: '../audio/mp3/story1/day01scene06.mp3',
    audioWav: '../audio/wav/story1/day01scene06.wav',
    hotspot: undefined,
    dialogue: {
      name: 'Akira',
      text: "Don't worry Giorgio, you'll see your daughter later.",
    },
    animation: undefined,
    positiveMoment: 0,
  },
  {
    src: images.day01scene07,
    alt: "Giorgio looks confused and sad. Akira doesn't notice.",
    audioMp3: '../audio/mp3/story1/day01scene07.mp3',
    audioWav: '../audio/wav/story1/day01scene07.wav',
    hotspot: undefined,
    dialogue: {
      name: 'Giorgio',
      text: 'Ahh...',
    },
    animation: undefined,
    positiveMoment: 0,
  },
  {
    src: images.day01scene08,
    alt: 'Akira smiles back at Giorgio as she leaves the room.',
    audioMp3: '../audio/mp3/story1/day01scene08.mp3',
    audioWav: '../audio/wav/story1/day01scene08.wav',
    hotspot: undefined,
    dialogue: {
      name: 'Akira',
      text: 'Enjoy Giorgio! See you tomorrow!',
    },
    animation: undefined,
    positiveMoment: 0,
  },
  {
    src: images.day01scene09,
    alt: 'Giorgio sits alone, staring into the distance, looking worried.',
    audioMp3: undefined,
    audioWav: undefined,
    hotspot: undefined,
    dialogue: undefined,
    animation: { type: 'autoplay', duration: 2000 },
    positiveMoment: 0,
  },
  {
    src: images.day01scene10,
    alt: 'Akira is gormless.',
    audioMp3: undefined,
    audioWav: undefined,
    hotspot: undefined,
    dialogue: undefined,
    animation: { type: 'fadeOut' },
    positiveMoment: 0,
  },
  {
    src: images.day01scene11,
    alt: 'Akira enters a new room and looks very chipper and upbeat.',
    audioMp3: '../audio/mp3/story1/day01scene11.mp3',
    audioWav: '../audio/wav/story1/day01scene11.wav',
    hotspot: undefined,
    dialogue: {
      name: 'Akira',
      text: 'Knock knock, hello Zahra.',
    },
    animation: { type: 'fadeIn' },
    positiveMoment: 0,
  },
  {
    src: images.day01scene12,
    alt:
      'Zahra, an older woman, sits up in bed looking despondent. Zahra is holding a photo frame.',
    audioMp3: undefined,
    audioWav: undefined,
    hotspot: undefined,
    dialogue: undefined,
    animation: { type: 'autoplay', duration: 2000 },
    positiveMoment: 0,
  },
  {
    src: images.day01scene13,
    alt: "Akira has moved to the side of Zahra's bed.",
    audioMp3: '../audio/mp3/story1/day01scene13.mp3',
    audioWav: '../audio/wav/story1/day01scene13.wav',
    hotspot: undefined,
    dialogue: {
      name: 'Zahra',
      text: 'Mmm? Oh, yes. Hello.',
    },
    animation: undefined,
    positiveMoment: 0,
  },
  {
    src: images.day01scene14,
    alt: 'Akira opens the curtains.',
    audioMp3: '../audio/mp3/story1/day01scene14.mp3',
    audioWav: '../audio/wav/story1/day01scene14.wav',
    hotspot: undefined,
    dialogue: {
      name: 'Akira',
      text: "It's a nice day for a walk, would you like that Zahra?",
    },
    animation: undefined,
    positiveMoment: 0,
  },
  {
    src: images.day01scene15,
    alt: 'Akira gathers laundry as Zahra sinks into her bed.',
    audioMp3: '../audio/mp3/story1/day01scene15.mp3',
    audioWav: '../audio/wav/story1/day01scene15.wav',
    hotspot: undefined,
    dialogue: {
      name: 'Zahra',
      text: 'No. I am tired. I think I will rest.',
    },
    animation: undefined,
    positiveMoment: 0,
  },
  {
    src: images.day01scene16,
    alt:
      "Akira stops by Zahra's bed to talk to her, but Zahra does not look engaged.",
    audioMp3: '../audio/mp3/story1/day01scene16.mp3',
    audioWav: '../audio/wav/story1/day01scene16.wav',
    hotspot: undefined,
    dialogue: {
      name: 'Akira',
      text: 'A shower is a great way to wake up.',
    },
    animation: undefined,
    positiveMoment: 0,
  },
  {
    src: images.day01scene17,
    alt: 'Akira sweeps up with a dustpan as Zahra looks at her photo frame.',
    audioMp3: '../audio/mp3/story1/day01scene17.mp3',
    audioWav: '../audio/wav/story1/day01scene17.wav',
    hotspot: undefined,
    dialogue: {
      name: 'Zahra',
      text:
        '... I miss my Abdul... you know we met nearly 50 years ago at the mosque.',
    },
    animation: undefined,
    positiveMoment: 0,
  },
  {
    src: images.day01scene18,
    alt: 'Akira looks up as she sweeps. Zahra is upset.',
    audioMp3: '../audio/mp3/story1/day01scene18.mp3',
    audioWav: '../audio/wav/story1/day01scene18.wav',
    hotspot: undefined,
    dialogue: {
      name: 'Akira',
      text:
        "50 years? Wow, I wasn't even born then. Ok, shower time! Let's get you up and...",
    },
    animation: undefined,
    positiveMoment: 0,
  },
  {
    src: images.day01scene19,
    alt: 'Akira looks surprised to see Zahra still in bed.',
    audioMp3: '../audio/mp3/story1/day01scene19.mp3',
    audioWav: '../audio/wav/story1/day01scene19.wav',
    hotspot: undefined,
    dialogue: {
      name: 'Zahra',
      text:
        "... No! I really don't want to. I am tired Akira. Go now and let me rest.",
    },
    animation: undefined,
    positiveMoment: 0,
  },
  {
    src: images.day01scene20,
    alt: 'Akira walks up to Zahra, carrying towels. Zahra looks depressed.',
    audioMp3: '../audio/mp3/story1/day01scene20.mp3',
    audioWav: '../audio/wav/story1/day01scene20.wav',
    hotspot: undefined,
    dialogue: {
      name: 'Akira',
      text: 'Come on, it will do you some good Zahra.',
    },
    animation: undefined,
    positiveMoment: 0,
  },
  {
    src: images.day01scene21,
    alt: 'Zahra turns away from Akira, bothered and upset.',
    audioMp3: '../audio/mp3/story1/day01scene21.mp3',
    audioWav: '../audio/wav/story1/day01scene21.wav',
    hotspot: undefined,
    dialogue: {
      name: 'Zahra',
      text: "I said I don't want to. Leave me alone.",
    },
    animation: undefined,
    positiveMoment: 0,
  },
  {
    src: images.day01scene22,
    alt: "Akira is stopped in her tracks. She doesn't know what to say.",
    audioMp3: undefined,
    audioWav: undefined,
    hotspot: undefined,
    dialogue: undefined,
    animation: { type: 'autoplay', duration: 2000 },
    positiveMoment: 0,
  },
  {
    src: images.day01scene23,
    alt: 'Akira looks downward, upset with the situation.',
    audioMp3: undefined,
    audioWav: undefined,
    hotspot: undefined,
    dialogue: undefined,
    animation: { type: 'fadeOut' },
    positiveMoment: 0,
  },
  {
    src: images.day01scene24,
    alt: 'Akira enters a new room, looking agitated.',
    audioMp3: '../audio/mp3/story1/day01scene24.mp3',
    audioWav: '../audio/wav/story1/day01scene24.wav',
    hotspot: undefined,
    dialogue: {
      name: 'Akira',
      text: 'Hello Patricia, it’s me, Akira.',
    },
    animation: { type: 'fadeIn' },
    positiveMoment: 0,
  },
  {
    src: images.day01scene25,
    alt:
      'Patricia, an older woman sitting at a table with a cup of tea and vase of flowers, looks bothered with Akira. Akira looks startled.',
    audioMp3: '../audio/mp3/story1/day01scene25.mp3',
    audioWav: '../audio/wav/story1/day01scene25.wav',
    hotspot: undefined,
    dialogue: {
      name: 'Patricia',
      text: "What took you so long? I'm sick of all this waiting around!",
    },
    animation: undefined,
    positiveMoment: 0,
  },
  {
    src: images.day01scene26,
    alt:
      'Akira has her hands on her hips and an annoyed look on her face. Patricia glares at Akira.',
    audioMp3: '../audio/mp3/story1/day01scene26.mp3',
    audioWav: '../audio/wav/story1/day01scene26.wav',
    hotspot: undefined,
    dialogue: {
      name: 'Akira',
      text:
        'You know I have a lot of other jobs to do. They told me to come to you later in the morning.',
    },
    animation: undefined,
    positiveMoment: 0,
  },
  {
    src: images.day01scene27,
    alt:
      'Patricia throws her arms up as she shouts and knocks over a vase on the table.',
    audioMp3: '../audio/mp3/story1/day01scene27.mp3',
    audioWav: '../audio/wav/story1/day01scene27.wav',
    hotspot: undefined,
    dialogue: {
      name: 'Patricia',
      text: 'They told me I needed care but what kind of care is this!',
    },
    animation: undefined,
    positiveMoment: 0,
  },
  {
    src: images.day01scene28,
    alt:
      'Akira looks very annoyed as she pulls out a cloth to clean up. Patricia has her arms folded and a stern expression.',
    audioMp3: '../audio/mp3/story1/day01scene28.mp3',
    audioWav: '../audio/wav/story1/day01scene28.wav',
    hotspot: undefined,
    dialogue: {
      name: 'Akira',
      text:
        "Patricia! Now look, you've made a mess. Don't touch it. Here, I'll do it.",
    },
    animation: undefined,
    positiveMoment: 0,
  },
  {
    src: images.day01scene29,
    alt:
      'Patricia turns away from Akira, her arms still folded. Akira looks upset.',
    audioMp3: '../audio/mp3/story1/day01scene29.mp3',
    audioWav: '../audio/wav/story1/day01scene29.wav',
    hotspot: undefined,
    dialogue: {
      name: 'Patricia',
      text: 'Get away! JUST GET AWAY!',
    },
    animation: undefined,
    positiveMoment: 0,
  },
  {
    src: images.day01scene30,
    alt:
      'Akira looks frazzled and worried as she attempts to calm down Patricia.',
    audioMp3: '../audio/mp3/story1/day01scene30.mp3',
    audioWav: '../audio/wav/story1/day01scene30.wav',
    hotspot: undefined,
    dialogue: {
      name: 'Akira',
      text: 'Now Patricia, there is no need to get upset. Accidents happen.',
    },
    animation: undefined,
    positiveMoment: 0,
  },
  {
    src: images.day01scene31,
    alt:
      'Patricia turns to Akira, pointing and staring directly at her as she yells.',
    audioMp3: '../audio/mp3/story1/day01scene31.mp3',
    audioWav: '../audio/wav/story1/day01scene31.wav',
    hotspot: undefined,
    dialogue: {
      name: 'Patricia',
      text: 'GET OUT!',
    },
    animation: undefined,
    positiveMoment: 0,
  },
  {
    src: images.day01scene32,
    alt: 'Akira looks dumbfounded.',
    audioMp3: undefined,
    audioWav: undefined,
    hotspot: undefined,
    dialogue: undefined,
    animation: { type: 'autoplay', duration: 1500 },
    positiveMoment: 0,
  },
  {
    src: images.day01scene33,
    alt: 'Akira looks defeated.',
    audioMp3: undefined,
    audioWav: undefined,
    hotspot: undefined,
    dialogue: undefined,
    animation: { type: 'autoplay', duration: 1500 },
    positiveMoment: 0,
  },
  {
    src: images.day01scene34,
    alt: 'Akira walks away, miserable.',
    audioMp3: undefined,
    audioWav: undefined,
    hotspot: undefined,
    dialogue: undefined,
    animation: { type: 'autoplay', duration: 1500 },
    positiveMoment: 0,
  },
  {
    src: images.day01scene35,
    alt: "Akira talks to herself as she leaves, closing Patricia's door.",
    audioMp3: '../audio/mp3/story1/day01scene35.mp3',
    audioWav: '../audio/wav/story1/day01scene35.wav',
    hotspot: undefined,
    dialogue: {
      name: undefined,
      text: 'What a day...',
    },
    animation: { type: 'fadeOut' },
    positiveMoment: 0,
  },
  {
    src: images.day01scene36,
    alt:
      'It is later in the day. Akira is walking outside with her colleague, Priya.',
    audioMp3: undefined,
    audioWav: undefined,
    hotspot: undefined,
    dialogue: undefined,
    animation: { type: 'fadeIn' },
    positiveMoment: 0,
  },
  {
    src: images.day01scene37,
    alt: 'Akira and Priya walk and talk.',
    audioMp3: '../audio/mp3/story1/day01scene37.mp3',
    audioWav: '../audio/wav/story1/day01scene37.wav',
    hotspot: undefined,
    dialogue: {
      name: 'Akira',
      text:
        'I don’t know Priya. I just found it really hard today... I know there is a better way.',
    },
    animation: undefined,
    positiveMoment: 0,
  },
  {
    src: images.day01scene38,
    alt: 'Priya talks, a kind expression on her face.',
    audioMp3: '../audio/mp3/story1/day01scene38.mp3',
    audioWav: '../audio/wav/story1/day01scene38.wav',
    hotspot: undefined,
    dialogue: {
      name: 'Priya',
      text: 'When I have a hard day I think, “How did my clients feel?”',
    },
    animation: undefined,
    positiveMoment: 0,
  },
  {
    src: images.day01scene39,
    alt: 'Akira stops in her tracks.',
    audioMp3: undefined,
    audioWav: undefined,
    hotspot: undefined,
    dialogue: undefined,
    animation: { type: 'autoplay', duration: 2000 },
    positiveMoment: 0,
  },
  {
    src: images.day01scene40,
    alt:
      'Giorgio appears above Akira as a vision in her imagination. He sits in his chair and looks down at her.',
    audioMp3: undefined,
    audioWav: undefined,
    hotspot: {
      label: 'Press ♥ to help Akira reconnect with Giorgio',
      x: '35%',
      y: '38%',
    },
    dialogue: undefined,
    animation: { fadeDuration: 1000 },
    positiveMoment: 0,
  },
  {
    src: images.day01scene41,
    alt: 'Giorgio talks to Akira. She looks saddened.',
    audioMp3: '../audio/mp3/story1/day01scene41.mp3',
    audioWav: '../audio/wav/story1/day01scene41.wav',
    hotspot: undefined,
    dialogue: {
      name: 'Giorgio',
      text:
        'Akira, you left me confused and alone about my daughter when I needed you to reassure me.',
    },
    animation: { fadeDuration: 1500 },
    positiveMoment: 1,
  },
  {
    src: images.day01scene42,
    alt:
      'Zahra appears in place of Giorgio. She is in bed holding the photo frame and looking down at Akira.',
    audioMp3: undefined,
    audioWav: undefined,
    hotspot: {
      label: 'Press ♥ to help Akira reconnect with Zahra',
      x: '32%',
      y: '42%',
    },
    dialogue: undefined,
    animation: { fadeDuration: 1000 },
    positiveMoment: 0,
  },
  {
    src: images.day01scene43,
    alt: 'Zahra talks to Akira, who looks even more saddened.',
    audioMp3: '../audio/mp3/story1/day01scene43.mp3',
    audioWav: '../audio/wav/story1/day01scene43.wav',
    hotspot: undefined,
    dialogue: {
      name: 'Zahra',
      text:
        "When you ignored me talking about my Abdul, you showed you didn't care about my past. I felt out of place.",
    },
    animation: { fadeDuration: 1500 },
    positiveMoment: 1,
  },
  {
    src: images.day01scene44,
    alt:
      'Patricia appears in place of Zahra. She sits at her table, arms folded and turning away from Akira.',
    audioMp3: undefined,
    audioWav: undefined,
    hotspot: {
      label: 'Press ♥ to help Akira reconnect with Patricia',
      x: '38%',
      y: '36%',
    },
    dialogue: undefined,
    animation: { fadeDuration: 1000 },
    positiveMoment: 0,
  },
  {
    src: images.day01scene45,
    alt:
      'Patricia, looking deeply sad, talks to Akira. Akira looks sadder still.',
    audioMp3: '../audio/mp3/story1/day01scene45.mp3',
    audioWav: '../audio/wav/story1/day01scene45.wav',
    hotspot: undefined,
    dialogue: {
      name: 'Patricia',
      text: 'Akira, the way you come and go, it’s like I’m not really here.',
    },
    animation: undefined,
    positiveMoment: 1,
  },
  {
    src: images.day01scene46,
    alt: 'Akira is alone. Staring into the distance.',
    audioMp3: undefined,
    audioWav: undefined,
    hotspot: undefined,
    dialogue: undefined,
    animation: { type: 'autoplay', duration: 2000 },
    positiveMoment: 0,
  },
  {
    src: images.day01scene47,
    alt: 'Rain starts to fall on Akira.',
    audioMp3: undefined,
    audioWav: undefined,
    hotspot: undefined,
    dialogue: undefined,
    animation: { type: 'autoplay', duration: 2000 },
    positiveMoment: 0,
  },
  {
    src: images.day01scene48,
    alt:
      'The background changes around Akira as it rains. She is now standing in her living room.',
    audioMp3: undefined,
    audioWav: undefined,
    hotspot: undefined,
    dialogue: undefined,
    animation: { type: 'autoplay', duration: 2000 },
    positiveMoment: 0,
  },
  {
    src: images.day01scene49,
    alt:
      'Akira sits on her couch in her living room, holding a cup of tea. A picture of a rainy scene hang on the wall. Her cat, Darcy, is asleep in his bed.',
    audioMp3: undefined,
    audioWav: undefined,
    hotspot: {
      label:
        'Press ♥ to help Akira reconnect with the impact care workers have on the lives of others',
      x: '50%',
      y: '54%',
    },
    dialogue: undefined,
    animation: { fadeDuration: 1000 },
    positiveMoment: 0,
  },
  {
    src: images.day01scene50,
    alt:
      'Akira speaks to herself as she sits on her couch. The rain eases in the picture on the wall. Darcy looks up.',
    audioMp3: '../audio/mp3/story1/day01scene50.mp3',
    audioWav: '../audio/wav/story1/day01scene50.wav',
    hotspot: undefined,
    dialogue: {
      name: 'Akira',
      text: 'I have a responsibility to do this better.',
    },
    animation: undefined,
    positiveMoment: 0,
  },
  {
    src: images.day01scene51,
    alt:
      'Akira has a gentle smile on her face. The picture on the wall is now free of rain. Darcy hops up onto the couch.',
    audioMp3: '../audio/mp3/story1/day01scene51.mp3',
    audioWav: '../audio/wav/story1/day01scene51.wav',
    hotspot: undefined,
    dialogue: {
      name: 'Akira',
      text: 'I have an opportunity to bring more meaning into people’s lives.',
    },
    animation: undefined,
    positiveMoment: 0.3,
  },
  {
    src: images.day01scene52,
    alt:
      'Akira gives Darcy a scratch behind the ear. The sun rises in the picture on the wall.',
    audioMp3: '../audio/mp3/story1/day01scene52.mp3',
    audioWav: '../audio/wav/story1/day01scene52.wav',
    hotspot: undefined,
    dialogue: {
      name: 'Akira',
      text: 'I can make a change.',
    },
    animation: undefined,
    positiveMoment: 0.6,
  },
  {
    src: images.day01scene53,
    alt:
      'Akira takes a sip of tea as Darcy rests on her lap. The sun shines in the picture on the wall.',
    audioMp3: undefined,
    audioWav: undefined,
    hotspot: undefined,
    dialogue: {
      name: undefined,
      text: 'Help Akira bring spiritual care back into her work.',
    },
    animation: { type: 'fadeOut' },
    positiveMoment: 1,
  },
  {
    src: images.day02scene54,
    alt:
      "It is a new day. Akira enters Giorgio's room. Giorgio is sitting in his chair.",
    audioMp3: '../audio/mp3/story1/day02scene54.mp3',
    audioWav: '../audio/wav/story1/day02scene54.wav',
    hotspot: undefined,
    dialogue: {
      name: 'Akira',
      text: "Good morning Giorgio! It's me, Akira, it's time for breakfast.",
    },
    animation: { type: 'fadeIn' },
    positiveMoment: 0,
  },
  {
    src: images.day02scene55,
    alt: 'Akira approaches Giorgio with his slippers.',
    audioMp3: '../audio/mp3/story1/day02scene55.mp3',
    audioWav: '../audio/wav/story1/day02scene55.wav',
    hotspot: undefined,
    dialogue: {
      name: 'Akira',
      text:
        'Here are your slippers Giorgio, would you like me to help you put them on?',
    },
    animation: undefined,
    positiveMoment: 0,
  },
  {
    src: images.day02scene56,
    alt: 'Akira approaches Giorgio with his slippers.',
    audioMp3: undefined,
    audioWav: undefined,
    hotspot: undefined,
    dialogue: undefined,
    animation: { type: 'autoplay' },
    positiveMoment: 0,
  },
  {
    src: images.day02scene57,
    alt: 'Akira approaches Giorgio with his slippers.',
    audioMp3: undefined,
    audioWav: undefined,
    hotspot: undefined,
    dialogue: undefined,
    animation: { type: 'autoplay' },
    positiveMoment: 0,
  },
  {
    src: images.day02scene58,
    alt: 'Akira approaches Giorgio with his slippers.',
    audioMp3: undefined,
    audioWav: undefined,
    hotspot: undefined,
    dialogue: undefined,
    animation: { type: 'autoplay' },
    positiveMoment: 0,
  },
  {
    src: images.day02scene59,
    alt: 'Akira helps Giorgio put his slippers on.',
    audioMp3: undefined,
    audioWav: undefined,
    hotspot: undefined,
    dialogue: undefined,
    animation: { type: 'autoplay' },
    positiveMoment: 0,
  },
  {
    src: images.day02scene60,
    alt: 'Akira kneels down and helps Giorgio put his slippers on.',
    audioMp3: '../audio/mp3/story1/day02scene60.mp3',
    audioWav: '../audio/wav/story1/day02scene60.wav',
    hotspot: undefined,
    dialogue: {
      name: 'Giorgio',
      text: 'What time does my daughter finish school?',
    },
    animation: undefined,
    positiveMoment: 0,
  },
  {
    src: images.day02scene61,
    alt: 'Akira pauses, not sure what to do.',
    audioMp3: undefined,
    audioWav: undefined,
    hotspot: {
      label: 'Press ♥ to reconnect Akira with her spiritual care practice',
      x: '31%',
      y: '42%',
    },
    dialogue: undefined,
    animation: { fadeDuration: 1000 },
    positiveMoment: 0,
  },
  {
    src: images.day02scene62,
    alt: 'Akira smiles.',
    audioMp3: undefined,
    audioWav: undefined,
    hotspot: undefined,
    dialogue: undefined,
    animation: { type: 'autoplay' },
    positiveMoment: 1,
  },
  {
    src: images.day02scene63,
    alt: 'Akira makes eye contact with Giorgio as she speaks.',
    audioMp3: '../audio/mp3/story1/day02scene63.mp3',
    audioWav: '../audio/wav/story1/day02scene63.wav',
    hotspot: undefined,
    dialogue: {
      name: 'Akira',
      text:
        'I know you love your daughter a lot. Would you like to tell me about her?',
    },
    animation: undefined,
    positiveMoment: 1,
  },
  {
    src: images.day02scene64,
    alt: "Akira continues with Giorgio's slippers. Giorgio looks happy.",
    audioMp3: '../audio/mp3/story1/day02scene64.mp3',
    audioWav: '../audio/wav/story1/day02scene64.wav',
    hotspot: undefined,
    dialogue: {
      name: 'Giorgio',
      text: 'My daughter, she is a kind person.',
    },
    animation: undefined,
    positiveMoment: 1,
  },
  {
    src: images.day02scene65,
    alt: 'Akira makes eye contact with Giorgio as she speaks.',
    audioMp3: '../audio/mp3/story1/day02scene65.mp3',
    audioWav: '../audio/wav/story1/day02scene65.wav',
    hotspot: undefined,
    dialogue: {
      name: 'Akira',
      text: "She is? That's great! Tell me more about her Giorgio.",
    },
    animation: undefined,
    positiveMoment: 1,
  },
  {
    src: images.day02scene66,
    alt: 'Giorgio is filled with joy.',
    audioMp3: '../audio/mp3/story1/day02scene66.mp3',
    audioWav: '../audio/wav/story1/day02scene66.wav',
    hotspot: undefined,
    dialogue: {
      name: 'Giorgio',
      text: 'She loves to cook!',
    },
    animation: undefined,
    positiveMoment: 1,
  },
  {
    src: images.day02scene67,
    alt: 'Akira stands, talking to Giorgio.',
    audioMp3: '../audio/mp3/story1/day02scene67.mp3',
    audioWav: '../audio/wav/story1/day02scene67.wav',
    hotspot: undefined,
    dialogue: {
      name: 'Akira',
      text: 'How lovely! I would like to taste her cooking one day!',
    },
    animation: undefined,
    positiveMoment: 1,
  },
  {
    src: images.day02scene68,
    alt: 'Akira offers Giorgio her hand.',
    audioMp3: '../audio/mp3/story1/day02scene68.mp3',
    audioWav: '../audio/wav/story1/day02scene68.wav',
    hotspot: undefined,
    dialogue: {
      name: 'Akira',
      text: 'How about we get some breakfast?',
    },
    animation: undefined,
    positiveMoment: 1,
  },
  {
    src: images.day02scene69,
    alt: 'Akira offers Giorgio her hand.',
    audioMp3: undefined,
    audioWav: undefined,
    hotspot: undefined,
    dialogue: undefined,
    animation: { type: 'autoplay' },
    positiveMoment: 1,
  },
  {
    src: images.day02scene70,
    alt: "Giorgio takes Akira's hand and she helps him up.",
    audioMp3: '../audio/mp3/story1/day02scene70.mp3',
    audioWav: '../audio/wav/story1/day02scene70.wav',
    hotspot: undefined,
    dialogue: {
      name: 'Giorgio',
      text: 'That would be nice.',
    },
    animation: undefined,
    positiveMoment: 1,
  },
  {
    src: images.day02scene71,
    alt: 'Akira and Giorgio walk together towards the door.',
    audioMp3: '../audio/mp3/story1/day02scene71.mp3',
    audioWav: '../audio/wav/story1/day02scene71.wav',
    hotspot: undefined,
    dialogue: {
      name: 'Akira',
      text: "I love my mother's curry, what's your favourite food Giorgio?...",
    },
    animation: { type: 'fadeOut' },
    positiveMoment: 1,
  },
  {
    src: images.day02scene72,
    alt: "Akira appears at Zahra's door to greet her.",
    audioMp3: '../audio/mp3/story1/day02scene72.mp3',
    audioWav: '../audio/wav/story1/day02scene72.wav',
    hotspot: undefined,
    dialogue: {
      name: 'Akira',
      text: 'Knock knock, hello Zahra. It is time to take a shower.',
    },
    animation: { type: 'fadeIn' },
    positiveMoment: 0,
  },
  {
    src: images.day02scene73,
    alt:
      'Zahra has her back to Akira and is tucked under her covers in bed. Akira waits patiently by her bed.',
    audioMp3: '../audio/mp3/story1/day02scene73.mp3',
    audioWav: '../audio/wav/story1/day02scene73.wav',
    hotspot: undefined,
    dialogue: {
      name: 'Zahra',
      text: 'Mmm? I am tired today.',
    },
    animation: undefined,
    positiveMoment: 0,
  },
  {
    src: images.day02scene74,
    alt: 'Zahra is annoyed and talks to Akira with her back to her.',
    audioMp3: '../audio/mp3/story1/day02scene74.mp3',
    audioWav: '../audio/wav/story1/day02scene74.wav',
    hotspot: undefined,
    dialogue: {
      name: 'Zahra',
      text: 'Please, go away.',
    },
    animation: undefined,
    positiveMoment: 0,
  },
  {
    src: images.day02scene75,
    alt: 'Akira looks worried. She pauses.',
    audioMp3: undefined,
    audioWav: undefined,
    hotspot: {
      label: 'Press ♥ to reconnect Akira with her spiritual care practice',
      x: '51%',
      y: '54%',
    },
    dialogue: undefined,
    animation: { fadeDuration: 1000 },
    positiveMoment: 0,
  },
  {
    src: images.day02scene76,
    alt: 'Akira smiles gently.',
    audioMp3: undefined,
    audioWav: undefined,
    hotspot: undefined,
    dialogue: undefined,
    animation: { type: 'autoplay' },
    positiveMoment: 1,
  },
  {
    src: images.day02scene77,
    alt: 'Akira speaks to Zahra. Zahra opens her eyes.',
    audioMp3: '../audio/mp3/story1/day02scene77.mp3',
    audioWav: '../audio/wav/story1/day02scene77.wav',
    hotspot: undefined,
    dialogue: {
      name: 'Akira',
      text: 'Oh dear, why is that Zahra, are you feeling ok?',
    },
    animation: undefined,
    positiveMoment: 1,
  },
  {
    src: images.day02scene78,
    alt:
      'Zahra sits up and holds her photo frame. Akira gives Zahra her full attention.',
    audioMp3: '../audio/mp3/story1/day02scene78.mp3',
    audioWav: '../audio/wav/story1/day02scene78.wav',
    hotspot: undefined,
    dialogue: {
      name: 'Zahra',
      text: 'I miss my husband Akira. I miss my life.',
    },
    animation: undefined,
    positiveMoment: 1,
  },
  {
    src: images.day02scene79,
    alt:
      'Akira speaks to Zahra. Zahra looks a little surprised that Akira is showing an interest.',
    audioMp3: '../audio/mp3/story1/day02scene79.mp3',
    audioWav: '../audio/wav/story1/day02scene79.wav',
    hotspot: undefined,
    dialogue: {
      name: 'Akira',
      text: 'I’d love to know more about your life Zahra.',
    },
    animation: undefined,
    positiveMoment: 1,
  },
  {
    src: images.day02scene80,
    alt:
      'Zahra shows Akira a picture of herself and Abdul when they were younger.',
    audioMp3: undefined,
    audioWav: undefined,
    hotspot: undefined,
    dialogue: undefined,
    animation: { type: 'autoplay' },
    positiveMoment: 1,
  },
  {
    src: images.day02scene81,
    alt:
      'Zahra shows Akira a picture of herself and Abdul when they were younger.',
    audioMp3: '../audio/mp3/story1/day02scene81.mp3',
    audioWav: '../audio/wav/story1/day02scene81.wav',
    hotspot: undefined,
    dialogue: {
      name: 'Zahra',
      text:
        'It was at a gathering of our community for Eid that I first saw Abdul.',
    },
    animation: undefined,
    positiveMoment: 1,
  },
  {
    src: images.day02scene82,
    alt: "Akira sits by Zahra's bed, making eye contact at her level.",
    audioMp3: '../audio/mp3/story1/day02scene82.mp3',
    audioWav: '../audio/wav/story1/day02scene82.wav',
    hotspot: undefined,
    dialogue: {
      name: 'Akira',
      text:
        "Oh, that's a nice a photo of you both, Zahra. Tell me some more about him while you shower.",
    },
    animation: undefined,
    positiveMoment: 1,
  },
  {
    src: images.day02scene83,
    alt: 'Akira and Zahra walk away together.',
    audioMp3: '../audio/mp3/story1/day02scene83.mp3',
    audioWav: '../audio/wav/story1/day02scene83.wav',
    hotspot: undefined,
    dialogue: {
      name: 'Zahra',
      text: 'Of course, I had heard about him from my parents...',
    },
    animation: { type: 'fadeOut' },
    positiveMoment: 1,
  },
  {
    src: images.day02scene84,
    alt:
      "Akira enters Patricia's room smiling and holding a tray of food and utensils. Patricia sits at her table with a cup of tea, giving Akira the side-eye.",
    audioMp3: '../audio/mp3/story1/day02scene84.mp3',
    audioWav: '../audio/wav/story1/day02scene84.wav',
    hotspot: undefined,
    dialogue: {
      name: 'Akira',
      text: "Patricia, it's me, Akira. Are you ready to prepare your meal?",
    },
    animation: { type: 'fadeIn' },
    positiveMoment: 0,
  },
  {
    src: images.day02scene85,
    alt:
      'Patricia turns away from Akira, her arms still folded. Akira looks upset.',
    audioMp3: '../audio/mp3/story1/day02scene85.mp3',
    audioWav: '../audio/wav/story1/day02scene85.wav',
    hotspot: undefined,
    dialogue: {
      name: 'Patricia',
      text: "About time. You're late. Come in.",
    },
    animation: undefined,
    positiveMoment: 0,
  },
  {
    src: images.day02scene86,
    alt: 'Akira pauses, holding the tray.',
    audioMp3: undefined,
    audioWav: undefined,
    hotspot: undefined,
    dialogue: undefined,
    animation: { type: 'autoplay', duration: 2000 },
    positiveMoment: 0,
  },
  {
    src: images.day02scene87,
    alt: 'Akira closes her eyes and takes a breath.',
    audioMp3: undefined,
    audioWav: undefined,
    hotspot: {
      label: 'Press ♥ to reconnect Akira with her spiritual care practice',
      x: '52%',
      y: '50%',
    },
    dialogue: undefined,
    animation: undefined,
    positiveMoment: 0,
  },
  {
    src: images.day02scene88,
    alt: 'Akira smiles gently.',
    audioMp3: undefined,
    audioWav: undefined,
    hotspot: undefined,
    dialogue: undefined,
    animation: { type: 'autoplay', duration: 2000 },
    positiveMoment: 1,
  },
  {
    src: images.day02scene89,
    alt: 'Akira puts the tray down and tentatively speaks to Patricia.',
    audioMp3: '../audio/mp3/story1/day02scene89.mp3',
    audioWav: '../audio/wav/story1/day02scene89.wav',
    hotspot: undefined,
    dialogue: {
      name: 'Akira',
      text: 'How are you today Patricia?',
    },
    animation: undefined,
    positiveMoment: 1,
  },
  {
    src: images.day02scene90,
    alt: 'Patricia is a little surprised.',
    audioMp3: undefined,
    audioWav: undefined,
    hotspot: undefined,
    dialogue: undefined,
    animation: { type: 'autoplay', duration: 2000 },
    positiveMoment: 1,
  },
  {
    src: images.day02scene91,
    alt: "Patricia's expression softens as she speaks.",
    audioMp3: '../audio/mp3/story1/day02scene91.mp3',
    audioWav: '../audio/wav/story1/day02scene91.wav',
    hotspot: undefined,
    dialogue: {
      name: 'Patricia',
      text: 'Better than yesterday.',
    },
    animation: undefined,
    positiveMoment: 1,
  },
  {
    src: images.day02scene92,
    alt: 'Akira speaks to Patricia. Patricia looks down.',
    audioMp3: '../audio/mp3/story1/day02scene92.mp3',
    audioWav: '../audio/wav/story1/day02scene92.wav',
    hotspot: undefined,
    dialogue: {
      name: 'Akira',
      text:
        "Yesterday didn't seem like a good day, and now today... is there something upsetting you?",
    },
    animation: undefined,
    positiveMoment: 1,
  },
  {
    src: images.day02scene93,
    alt: 'Patricia looks down, thinking.',
    audioMp3: undefined,
    audioWav: undefined,
    hotspot: undefined,
    dialogue: undefined,
    animation: { type: 'autoplay', duration: 2000, fadeDuration: 1500 },
    positiveMoment: 1,
  },
  {
    src: images.day02scene94,
    alt: 'Patricia closes her eyes.',
    audioMp3: undefined,
    audioWav: undefined,
    hotspot: undefined,
    dialogue: undefined,
    animation: { type: 'autoplay', duration: 1500 },
    positiveMoment: 1,
  },
  {
    src: images.day02scene95,
    alt: 'Akira sits at the table as Patricia turns and talks to her.',
    audioMp3: '../audio/mp3/story1/day02scene95.mp3',
    audioWav: '../audio/wav/story1/day02scene95.wav',
    hotspot: undefined,
    dialogue: {
      name: 'Patricia',
      text: 'I miss my life Akira. I feel like I have no choices left.',
    },
    animation: undefined,
    positiveMoment: 1,
  },
  {
    src: images.day02scene96,
    alt: 'Akira speaks to Patricia.',
    audioMp3: '../audio/mp3/story1/day02scene96.mp3',
    audioWav: '../audio/wav/story1/day02scene96.wav',
    hotspot: undefined,
    dialogue: {
      name: 'Akira',
      text: 'No choices?',
    },
    animation: undefined,
    positiveMoment: 1,
  },
  {
    src: images.day02scene97,
    alt: 'Akira smiles and speaks to Patricia.',
    audioMp3: '../audio/mp3/story1/day02scene97.mp3',
    audioWav: '../audio/wav/story1/day02scene97.wav',
    hotspot: undefined,
    dialogue: {
      name: 'Akira',
      text: 'We can work on that together while we prepare your meal.',
    },
    animation: undefined,
    positiveMoment: 1,
  },
  {
    src: images.day02scene98,
    alt: 'Patricia pauses.',
    audioMp3: undefined,
    audioWav: undefined,
    hotspot: undefined,
    dialogue: undefined,
    animation: { type: 'autoplay' },
    positiveMoment: 1,
  },
  {
    src: images.day02scene99,
    alt: 'Patricia looks at Akira.',
    audioMp3: undefined,
    audioWav: undefined,
    hotspot: undefined,
    dialogue: undefined,
    animation: { type: 'autoplay', duration: 2000 },
    positiveMoment: 1,
  },
  {
    src: images.day02scene100,
    alt: 'Patricia smiles slightly.',
    audioMp3: undefined,
    audioWav: undefined,
    hotspot: undefined,
    dialogue: undefined,
    animation: { type: 'autoplay', duration: 2000 },
    positiveMoment: 1,
  },
  {
    src: images.day02scene101,
    alt: 'Patricia speaks to Akira, smiling.',
    audioMp3: '../audio/mp3/story1/day02scene101.mp3',
    audioWav: '../audio/wav/story1/day02scene101.wav',
    hotspot: undefined,
    dialogue: {
      name: 'Patricia',
      text: 'I would like that, Akira.',
    },
    animation: undefined,
    positiveMoment: 1,
  },
  {
    src: images.day02scene102,
    alt: 'Patricia smiles.',
    audioMp3: undefined,
    audioWav: undefined,
    hotspot: undefined,
    dialogue: undefined,
    animation: { type: 'fadeOut' },
    positiveMoment: 1,
  },
  {
    src: images.day02scene103,
    alt:
      'Akira is in her living room, sitting on her couch with a cup of tea. She is smiling. Darcy is napping in her lap.',
    audioMp3: '../audio/mp3/story1/day02scene103.mp3',
    audioWav: '../audio/wav/story1/day02scene103.wav',
    hotspot: undefined,
    dialogue: {
      name: undefined,
      text: 'How did the people I care for feel today?',
    },
    animation: { type: 'fadeIn' },
    positiveMoment: 1,
  },
  {
    src: images.day02scene104,
    alt:
      'Giorgio appears above Akira as a vision in her imagination. He sits in his chair and looks down at her, smiling. Akira smiles back.',
    audioMp3: undefined,
    audioWav: undefined,
    hotspot: {
      label: 'Press ♥ to help Akira reconnect with Giorgio',
      x: '36%',
      y: '38%',
    },
    dialogue: undefined,
    animation: { fadeDuration: 1000 },
    positiveMoment: 0,
  },
  {
    src: images.day02scene105,
    alt: 'Giorgio speaks to Akira happily. Akira smiles.',
    audioMp3: '../audio/mp3/story1/day02scene105.mp3',
    audioWav: '../audio/wav/story1/day02scene105.wav',
    hotspot: undefined,
    dialogue: {
      name: 'Giorgio',
      text:
        'You were patient with me, Akira. You gave me time to speak. Instead of correcting me, you invited me to talk about my daughter, which makes me happy.',
    },
    animation: undefined,
    positiveMoment: 1,
  },
  {
    src: images.day02scene106,
    alt:
      'Zahra appears in place of Giorgio. She is in bed holding the photo frame and looking down at Akira, smiling.',
    audioMp3: undefined,
    audioWav: undefined,
    hotspot: {
      label: 'Press ♥ to help Akira reconnect with Zahra',
      x: '31%',
      y: '43%',
    },
    dialogue: undefined,
    animation: { fadeDuration: 1000 },
    positiveMoment: 0,
  },
  {
    src: images.day02scene107,
    alt: 'Zahra speaks to Akira happily. Akira smiles.',
    audioMp3: '../audio/mp3/story1/day02scene107.mp3',
    audioWav: '../audio/wav/story1/day02scene107.wav',
    hotspot: undefined,
    dialogue: {
      name: 'Zahra',
      text:
        'Akira, you showed interest in me and my life. You showed me that I have a place where I can explore my past and be myself.',
    },
    animation: undefined,
    positiveMoment: 1,
  },
  {
    src: images.day02scene108,
    alt:
      'Patricia appears in place of Zahra. She sits at her table, smiling at Akira.',
    audioMp3: undefined,
    audioWav: undefined,
    hotspot: {
      label: 'Press ♥ to help Akira reconnect with Patricia',
      x: '38%',
      y: '34%',
    },
    dialogue: undefined,
    animation: { fadeDuration: 1000 },
    positiveMoment: 0,
  },
  {
    src: images.day02scene109,
    alt: 'Patricia speaks to Akira happily. Akira smiles.',
    audioMp3: '../audio/mp3/story1/day02scene109.mp3',
    audioWav: '../audio/wav/story1/day02scene109.wav',
    hotspot: undefined,
    dialogue: {
      name: 'Patricia',
      text:
        'You listened to me and learned what was making me angry. You supported me and it felt like we were a team.',
    },
    animation: { type: 'fadeOut' },
    positiveMoment: 1,
  },
  {
    src: images.day02scene110,
    alt:
      'Akira and Giorgio stand side-by-side, looking out towards the viewer, smiling.',
    audioMp3: undefined,
    audioWav: undefined,
    hotspot: undefined,
    dialogue: {
      name: undefined,
      text:
        'Akira reflected, paused and connected with each person. What helps you connect?',
    },
    animation: { type: 'fadeIn' },
    positiveMoment: 1,
  },
] as const;

export default scenes;
