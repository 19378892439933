import { useEffect, useRef } from 'react';

// useEffect that doesn't run on the first render
const useRerender: typeof useEffect = (effect, deps) => {
  const firstRender = useRef(true);
  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
      return undefined;
    }
    return effect();
  }, deps);
};

export default useRerender;
