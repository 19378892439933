import React, { useContext, useRef } from 'react';
import NextLink from 'next/link';
import { Flex, Heading, Icon, Image, Text } from '@chakra-ui/core';
import type { IModal } from '@chakra-ui/core';

import { Button, Modal } from '@/components/ui';
import { rem } from '@/utils/theme';
import AudioContext from '@/utils/audioContext';

type Props = Omit<IModal, 'children'>;

const AudioModal: React.FC<Props> = ({ isOpen = false, onClose, ...rest }) => {
  const buttonEl = useRef<HTMLButtonElement>(null);
  const { setIsAudioOn } = useContext(AudioContext);

  const handleAudio = (
    event: React.MouseEvent<Element, MouseEvent> | React.KeyboardEvent<Element>,
    isAudioOn: boolean
  ) => {
    setIsAudioOn(isAudioOn);
    if (onClose !== undefined) {
      onClose(event);
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} variant="audio" {...rest}>
      <Image
        alt=""
        src={require('@/images/shapes/homepage-desktop-background.svg')}
        margin="auto"
        left="-50%"
        top={4}
        textAlign="center"
        position="absolute"
        width="250%"
        maxWidth="250%"
        ignoreFallback
      />
      <Flex
        direction="column"
        paddingX={4}
        paddingTop={13}
        paddingBottom={rem('40px')}
      >
        <Heading as="h1" fontSize={rem('23px')} zIndex={5}>
          Would you like audio turned on?
        </Heading>
        <Text fontSize="md" marginTop={4} zIndex={5}>
          This is an interactive experience that is enhanced with audio turned
          on. There will also be captions so you can read along if you need to.
        </Text>
        <NextLink href="/story" as="/story/" passHref>
          <Button
            ref={buttonEl}
            variant="green"
            marginTop={12}
            onClick={(e) => handleAudio(e, true)}
          >
            Turn on audio
            <Icon
              name="audioOn"
              width={rem('24px')}
              height={rem('24px')}
              marginLeft={3}
            />
          </Button>
        </NextLink>
        <NextLink href="/story" as="/story/" passHref>
          <Button
            variant="transparent"
            fontWeight="semibold"
            marginTop={5}
            onClick={(e) => handleAudio(e, false)}
          >
            No thanks
            <Icon
              name="cross"
              width={rem('20px')}
              height={rem('20px')}
              marginLeft={rem('18px')}
            />
          </Button>
        </NextLink>
      </Flex>
    </Modal>
  );
};

export default AudioModal;
