import React, { useEffect } from 'react';
import Head from 'next/head';
import { useDisclosure } from '@chakra-ui/core';
import type { NextPage } from 'next';

import story1 from '@/utils/scenes/story1';
import { Home } from '@/components/pages';
import generateMetaTags from '@/utils/meta';

const Index: NextPage = () => {
  const termsAndConditionsModal = useDisclosure(false);
  const audioModal = useDisclosure(false);

  // Preload the first 3 images of story 1
  useEffect(() => {
    for (let i = 0; i < 3; i += 1) {
      const scene = story1[i];
      const img = new Image();
      img.src = scene.src;
    }
  }, []);

  return (
    <>
      <Head>{generateMetaTags()}</Head>
      <Home
        termsAndConditionsModal={termsAndConditionsModal}
        audioModal={audioModal}
      />
    </>
  );
};

export default Index;
